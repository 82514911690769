//Testing
/*window.onload = function() {
	window.setTimeout(function(){
		window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());

		gtag('config', 'G-BHT952W6V1');
		
		// gtag('get', 'YOUR_MEASUREMENT_ID', 'client_id', (client_id) => {})
		gtag('get', 'G-BHT952W6V1', 'client_id', (client_id) => {
			// do something with client_id
			window.client_id = client_id
			

			console.log(client_id)
		})
	}, 250);	
};*/

//Production
window.onload = function() {
    window.setTimeout(function(){
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-S2JBCD0HKN');

        // gtag('get', 'YOUR_MEASUREMENT_ID', 'client_id', (client_id) => {})
        gtag('get', 'G-S2JBCD0HKN', 'client_id', (client_id) => {
            // do something with client_id
            window.client_id = client_id


            console.log(client_id)
        })
    }, 250);
};

